<script setup lang="ts">
interface IProps {
    blok: PageCtaButtonV2BlokInterface;
}
const props = defineProps<IProps>();

const outline = computed(() => props.blok.variant === 'dark-bg-secondary' || props.blok.outline);
const variant = computed(() => (props.blok.variant === 'dark-bg-secondary' ? 'dark-bg' : props.blok.variant));
</script>

<template>
    <es-button
        v-editable="blok"
        :variant="variant"
        :outline="outline"
        class="PageCtaButton px-sm-300 w-100 w-sm-auto justify-content-center"
        :href="blok.url.url">
        <span class="d-none d-lg-inline">
            {{ blok.desktopText || blok.text }}
        </span>
        <span class="d-inline d-lg-none">
            {{ blok.text }}
        </span>
    </es-button>
</template>
